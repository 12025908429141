import React from "react"
import { Modal, Form, Button, InputGroup, FormControl } from "react-bootstrap"
import "../styles/main.scss"
import padlock from "../images/padlock-open.svg"
import arrow from "../images/arrow.svg"
import { translate } from "../utils"

const ModalSide = () => {
  return (
    <div className="modal-right-wrap">
      <Modal.Dialog>
        <div className="d-flex d-md-none back-btn">
          <a href={'#'}>
            <img
              width={15.07}
              height={6.312}
              className="img-fluid"
              src={arrow}
              alt=""
            />
          </a>
        </div>
        <Modal.Header>
          <Modal.Title>
            <span>Your team</span>
          </Modal.Title>
          <div className="">
            <Button>Delete</Button>
            <Button>Add</Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <img
                    width={14}
                    height={20}
                    className="img-fluid"
                    src={padlock}
                    alt=""
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Email"
                aria-label=""
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl
                aria-label=""
                placeholder="Admin - Jérôme Duchateaux"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl aria-label="" placeholder={translate("team members")} />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl aria-label="" placeholder="Team members" />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl aria-label="" placeholder="Team members" />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl aria-label="" placeholder="Team members" />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox aria-label="" />
              </InputGroup.Prepend>
              <FormControl aria-label="" placeholder="Team members" />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}

export default ModalSide
